:root {
  --navColor:  rgb(45, 37, 52);
  --mainColor: rgb(237, 91, 62);
  --altColor: white;
}

.App {
    text-align: center;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  html {
      scroll-behavior: smooth;
    }


h1 {
  color: black;
  cursor:context-menu;
  }

footer h1 {
  margin:2rem 0 1rem 0;
  color:rgb(75, 75, 75);
}

/* unvisited link */
a:link,a:visited{
  color: var(--navColor);
}


img {
  height:100%;
  width:100%;
  object-fit: contain;
  object-position: center;
  display: block;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  display:none
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(184, 184, 184); 
  border-radius:5px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(111, 111, 111); 
}

.home{
  width:100%;
  min-height:95vh;
  position: relative;
}


.home .offset{
  position:absolute;
  inset:30% 0 0 0
}



@media all and (max-width: 480px) {
  .home .offset{
   inset:25% 0 0 0
 }

 .home img {
  scale:1.3
}
}


.homeMain-text {
  color:black;
  text-align: center;
  position: absolute;
  inset: 0;
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
}

.fg-transparent {
  opacity:0
}

.slideUp{
  animation: slideUp 0.5s
}


@keyframes slideUp {
  0% {
    opacity:1;
    transform: translateY(100px)
  }
100% {
  opacity:1;
}
}

#scrollDownChevron{
  display:flex;
  flex-direction: column;
  margin:1rem 1rem 0 0;
  width: fit-content;
  position:absolute;
  bottom:1rem;
  right:0;
  z-index:9;
  color: var(--mainColor);
  font-size: 1.5rem;
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration:1.4s;
  animation-iteration-count:infinite;
  animation-timing-function:linear;
}

@keyframes bounceAlpha {
  0% {opacity: 1; transform: translateY(0px) scale(1);}
  25%{opacity: 0; transform:translateY(10px) scale(0.9);}
  26%{opacity: 0; transform:translateY(-10px) scale(0.9);}
  55% {opacity: 1; transform: translateY(0px) scale(1);}
}


.banner {
  width: 100%;
  object-fit: cover;
}

.banner img {
  /* height:100%; */
  width:100%;
  object-fit: cover;
  object-position: 50% 50%
}



.section {
  background-color:var(--altColor);
  width:100%;
  height:100%;
  box-sizing: border-box;
  padding: 1rem 0;
  min-height: 50vh;
  height:fit-content;
  display:flex;
  flex-direction: row;
}

.section.alt {
  padding:2% 0;
  border-top:0.1rem solid rgb(222, 222, 222);
}
.section.alt img{
  box-sizing: border-box;
  margin:1%;
  max-height: 60vh;
}

.section h1{
  cursor:pointer
}

@media all and (max-width: 768px) {
  .section {
    flex-direction: column;
    max-width:100%;  
 }
}


.image-cont {
  display: flex;
  flex-direction: column;
 }

.image {
  box-sizing: border-box;
  width:100%;
  height: 100%;
  margin:auto;
  position: relative;
  }



.section .carousel-item { 
  align-items: center;  
  width:100%;
  height: 60vh;
  position: relative;
  box-sizing: border-box;
  margin:0 0 2rem 0;
}

.section .carousel-item img {
  object-fit: contain;
  box-sizing: border-box;
  margin: 0 auto;
}

.carousel-container .carousel .control-dots .dot {
            box-shadow: 1px 1px 2px rgba(54, 28, 73, 0.58);
            background: var(--mainColor);
            border-radius: 0%;
            width: 16px;
            height: 5px;
            cursor: pointer;
            display: inline-block;
            margin: 0 2px;
}

.carousel-container .carousel .control-next.control-arrow, .carousel-container .carousel .control-next.control-arrow:hover{
  background-color: transparent;
}

.carousel-container .carousel .control-prev.control-arrow, .carousel-container .carousel .control-prev.control-arrow:hover {
  background-color: transparent;
}

.carousel-container .carousel .control-next.control-arrow:before {
  border-left:10px solid rgb(30, 30, 30);
}

.carousel-container .carousel .control-prev.control-arrow:before {
  border-right:10px solid rgb(30, 30, 30);
}

.overlay {
  width:100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #9f9f9f9b;
  position: absolute;
  bottom:0;
}

.image:hover .overlay {
  opacity: 1;
}

.img-credit {
  color: white;
  text-align: right;
  padding:0 1em;
}

.section .text {
  width:100%;
  height:100%;
  min-height: 30vh;
  position: relative;
  box-sizing: border-box;
  padding:1rem 0
}


.section .text-container {
  height:100%;
  display:flex;
  flex-direction: column;
  cursor: context-menu;
  line-height:1.8em;
  overflow-wrap: break-word;
  justify-content: flex-start;
  margin:5% 10%;
  box-sizing: border-box;

}

.section .text.left{
  text-align: left;
}


.section .text.right{
  text-align: right;
}

.body-wrapper {
  margin: 1rem 0 2rem 0;
}

.button-wrapper {
  width:fit-content;
  height:fit-content;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
}

.my-button {
  color: var(--mainColor);
  background-color:transparent;
  cursor: pointer;
  transition: transform ease 400ms;
}

.my-button:hover {
  transform: scale(1.10);
}


.my-button:active {
  transform: scale(1);
}

.my-button.more{
  border:none;
  border-radius: 20px;
  padding:0.5rem 1rem;
  cursor: pointer;
  transition: transform ease 400ms;
}

.my-button.more:hover {
  color:var(--altColor);
  background-color: var(--mainColor);
  transform: scale(1.1);
  }



.icon-menu {
  padding:1rem 1rem 0 0;
  width: fit-content;
  position:fixed;
  top:0;
  right:0;
  z-index:9;
  font-size: 2rem;
}

.social-icon-container {
  margin:0 0 1rem 0;
  font-size: 2rem;
}


.social-icon-container .my-button {
  margin: 0.5rem;
  padding:0.5rem ;
  border-radius: 50%;
  transition: transform ease 400ms;
  cursor:pointer;
}

.social-icon-container .my-button:hover {
  color:var(--altColor);
  background-color: var(--mainColor);
  transform: scale(1.1);
  }

  .social-icon-container .my-button:active {
    transform: scale(1.0);
  } 


footer p {
  font-size: 10px;
  margin-bottom:1rem
}


.page {
  position:fixed;
  top:0;
  left:0; 
  display:flex;
  flex-direction: row;
  z-index: 10;
  background-color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y:scroll;
}

#pageProjects {
  display:flex;
  flex-direction: row;
  width: calc(100% - 3rem);
  height: 100%;
}


.page .panel {
  box-sizing: border-box;
  padding:5%;
  height:100%;
  width:100%;
  text-align: left;  
}


.page .gallery {
  max-height:100%;
  min-width:60%;
}


.page[animation="animateIn"] {
  animation: animateInLeft 0.5s ease-out;
}

.page[animation="animateOut"] {
  animation: animateOutLeft 0.5s ease-in;
  opacity: 0;
}


.page .closeModal-container {
  position:sticky;
  top:0;
  right:0;
}

.page .closeModal {
 border-radius: 20px;
  padding:1rem;
  font-size: 2rem;
  cursor: pointer;
}


@keyframes animateInLeft {
  from {left: -1000px}
  to {left: 0}
}
@keyframes animateOutLeft {
  from {left: 0;opacity:1}
  to {left: -1000px;opacity:1}
}


.page .text-block {
  height: 100%;
  width: 100%;
  clear:both;
  overflow-y: scroll;
  box-sizing: border-box;
  margin-bottom: 2rem;
  line-height:1.8em;
}

.page .text-block .image{
  box-sizing: border-box;
  max-width: 40%;
  margin:1rem 0 ;
}

.page .text-block .image.right{
  float:right;
  margin-left:2rem;
}
.page .text-block .image.left{
  float:left;
  margin-right:2rem;
}



@media all and (max-width: 480px) {

  #pageProjects {
    flex-direction: column;
  }

  .page .panel {
    margin-left: 2em;
    flex-direction: column;
  }

  .page .gallery{
    box-sizing: border-box;
    min-height:80vh;
    margin-left:3em;
   }
  
  .page .text-block {
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
  }
  .page .text-block .image{
    min-width: 100%;
  }

  .page .text-block .image.right, .page .text-block .image.left{
    margin:0
  }

}


.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.slide-left {
  animation: slide-in-left 0.8s ease-in-out both;
}

.scrolled.slide-right {
  animation: slide-in-right 0.8s ease-in-out both;
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}