html, body {
  width : 100%;
  height: 100%;
}


.landing-container {
  width:100%;
  min-height:100vh;
  display:flex;
  flex-direction: row;
  background: white;
  position: relative;
  cursor:wait; 
}

.landing-container img
{   position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    object-fit: contain;
}

@media all and (max-width: 768px) { 
  .landing-container {
    min-height:70vh;
  }
}


.overlay-container {
  width:100%;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index:4;
  transition: opacity ease-in 500ms;
}

.overlay-container>img {
  opacity: 0;
  z-index:4;
}

.hover-show {
  width:calc(100% / 7);
  height:100%;
  z-index: 5;
  display:flex;
  flex-direction: column;
  pointer-events: none; 
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

.face img {
  opacity: 0;
  transition: opacity ease-out 1s;
}

.fade-in #text
{
z-index: 3;
opacity: 1;
}

.fade-in #bg
{
z-index: 1;
animation-duration: 1.5s;
opacity: 1;
}

.fade-in #fg
{
z-index: 2;
animation-duration: 2.5s;
opacity: 1;
}

.fade-in #hoverdelay {
  animation-duration: 2.5s;
  opacity: 1;
}

.fade-in-out {
  z-index:8;
  animation: fadeInOut ease 1.5s;
  opacity:1;
}

.fade-in {
  animation: fadeIn ease 1s;
}

.zoom {
  animation: zoom ease 2s;

}

@keyframes fadeIn {
  0% {
    opacity:0;
    visibility: hidden;
    pointer-events: none;
    
  }
  50% {
    opacity:0;
    visibility: hidden;
    pointer-events: none;
  }

  100% {
    opacity:1;
    visibility: visible;
    pointer-events: none;
  }
}

@keyframes zoom {
  0% {
    opacity:0;
    visibility: hidden;
  }
  50% {
    opacity:0;
    visibility: hidden;
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    opacity:1;
    visibility: visible;
    transform: scale(1);
  }
}

@keyframes fadeInOut {
  0% {
    opacity:0;
    pointer-events: none;
  }
  50% {
    opacity:1;
    pointer-events: none;
}
100% {
  opacity:0;
  pointer-events: none;
}
}